import * as types from './types'

const initialState = false
const bulkImportTableInitialState = []

function hourlyRentalReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FEATUREFLAG_HOURLY_RENTAL:
      return typeof action.value === 'boolean' ? action.value : initialState

    default:
      return state
  }
}

const initialVanBookingState = {
  customServices: [],
  orderQuotation: null,
}

function vanBookingReducer(state = initialVanBookingState, action = {}) {
  switch (action.type) {
    case types.DISPLAY_CUSTOM_SERVICES:
      return {
        ...state,
        customServices: action.list,
      }

    case types.DISPLAY_ORDER_QUOTATION:
      return {
        ...state,
        orderQuotation: action.quotation,
      }

    default:
      return state
  }
}

function favoriteDriversReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FEATUREFLAG_FAVORITE_DRIVERS:
      return typeof action.value === 'boolean' ? action.value : initialState

    default:
      return state
  }
}

function bulkImportTableReducer(
  state = bulkImportTableInitialState,
  action = {}
) {
  switch (action.type) {
    case types.BULK_IMPORT_SET_TABLE_DATA:
      return action.value

    case types.BULK_IMPORT_EDIT_TABLE_DATA: {
      const { value, rowId, column } = action
      const copyAndModifiedRow = {
        ...state.find(row => row.orderIndex === rowId),
      }
      copyAndModifiedRow[column] = value

      return state.map(row =>
        row.orderIndex === rowId ? copyAndModifiedRow : { ...row }
      )
    }

    case types.BULK_IMPORT_DELETE_TABLE_DATA: {
      const { column, rowId } = action
      const copyAndModifiedRow = {
        ...state.find(row => row.orderIndex === rowId),
      }
      delete copyAndModifiedRow[column]

      return state.map(row =>
        row.orderIndex === rowId ? copyAndModifiedRow : { ...row }
      )
    }

    case types.BULK_IMPORT_DELETE_TABLE_ROW: {
      const { rowId } = action
      return state.filter(order => order.orderIndex !== rowId)
    }

    default:
      return state
  }
}

function bulkImportQuoteInProgressReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.BULK_IMPORT_SET_QUOTE_IN_PROGRESS:
      return typeof action.value === 'boolean' ? action.value : initialState

    default:
      return state
  }
}

function bulkImportQuotationReducer(state = 0, action = {}) {
  switch (action.type) {
    case types.BULK_IMPORT_SET_QUOTATION:
      return action.value

    default:
      return state
  }
}

function fixedMovingFeeReducer(state = false, action = {}) {
  switch (action.type) {
    case types.FEATUREFLAG_FIXED_MOVING_FEE:
      return typeof action.value === 'boolean' ? action.value : false

    default:
      return state
  }
}

const vanBookingReducers = {
  featureFlagHourlyRental: hourlyRentalReducer,
  vanBooking: vanBookingReducer,
  featureFlagFavoriteDrivers: favoriteDriversReducer,
  vanBookingBulkImportTable: bulkImportTableReducer,
  vanBookingBulkImportQuotation: bulkImportQuotationReducer,
  vanBookingBulkImportQuoteInProgress: bulkImportQuoteInProgressReducer,
  featureFlagFixedMovingFee: fixedMovingFeeReducer,
}

export { vanBookingReducers }
