const FEATUREFLAG_HOURLY_RENTAL = 'FEATUREFLAG_HOURLY_RENTAL'
const DISPLAY_CUSTOM_SERVICES = 'DISPLAY_CUSTOM_SERVICES'
const DISPLAY_ORDER_QUOTATION = 'DISPLAY_ORDER_QUOTATION'
const FEATUREFLAG_FAVORITE_DRIVERS = 'FEATUREFLAG_FAVORITE_DRIVERS'
const BULK_IMPORT_SET_TABLE_DATA = 'BULK_IMPORT_SET_TABLE_DATA'
const BULK_IMPORT_SET_QUOTATION = 'BULK_IMPORT_SET_QUOTATION'
const BULK_IMPORT_EDIT_TABLE_DATA = 'BULK_IMPORT_EDIT_TABLE_DATA'
const BULK_IMPORT_DELETE_TABLE_DATA = 'BULK_IMPORT_DELETE_TABLE_DATA'
const BULK_IMPORT_DELETE_TABLE_ROW = 'BULK_IMPORT_DELETE_TABLE_ROW'
const BULK_IMPORT_SET_QUOTE_IN_PROGRESS = 'BULK_IMPORT_SET_QUOTE_IN_PROGRESS'
const FEATUREFLAG_FIXED_MOVING_FEE = 'FEATUREFLAG_FIXED_MOVING_FEE'

export {
  FEATUREFLAG_HOURLY_RENTAL,
  DISPLAY_CUSTOM_SERVICES,
  DISPLAY_ORDER_QUOTATION,
  FEATUREFLAG_FAVORITE_DRIVERS,
  BULK_IMPORT_SET_TABLE_DATA,
  BULK_IMPORT_SET_QUOTATION,
  BULK_IMPORT_DELETE_TABLE_DATA,
  BULK_IMPORT_EDIT_TABLE_DATA,
  BULK_IMPORT_SET_QUOTE_IN_PROGRESS,
  BULK_IMPORT_DELETE_TABLE_ROW,
  FEATUREFLAG_FIXED_MOVING_FEE,
}
