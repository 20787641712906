import { useCallback } from 'react'
import _ from 'lodash'
import IndexRedirect from 'react-router/lib/IndexRedirect'
import Route from 'react-router/lib/Route'
import { push } from 'react-router-redux'
import { validResetPasswordToken } from '../actions/users'
import Actions from '../actions/session'
import { api } from '../utils/api'
import { observeStore } from '../utils'
import { storeNewLanguageAndReload } from '../utils/language'
import { handleLanguageParameter } from '../../service/i18n/language-parameter.route'

import { loadableCreator } from '../../delivery/loading/loadable-creator'
import { featureFlagService } from '../../service/feature-flag/feature-flag'
import MainLayout from '../views/main'
import AuthenticatedContainer from '../containers/authenticated'
import PageNotFoundView from '../../404/404'

import AuthErrorView from '../../auth-error/auth-error.component'
import Constants from '../constants/auth'

import { AppRoutes } from '../../app.routes'
import { SelfRegistrationRoutes } from '../../self-registration/self-registration.routes'
import { PrintLabelsRoute } from '../../delivery/delivery.routes'
import { PaidSubscriptionPlanRoutes } from '../../paid-subscription-plan/paid-subscription-plan.routes'
import { getUserId } from '../views/helpers'

const LoadableLogin = loadableCreator({
  dynamicImport: () =>
    import(/* webpackChunkName: 'login' */ '../../login/login.component'),
  componentName: 'MemoLogin',
})

const LoadableForgotPassword = loadableCreator({
  dynamicImport: () =>
    import(
      /* webpackChunkName: 'forgot-password' */ '../../forgot-password/forgot-password.component'
    ),
  componentName: 'ForgotPassword',
})

const LoadableCustomerService = loadableCreator({
  dynamicImport: () =>
    import(
      /* webpackChunkName: 'customer-service' */ '../../customer-service/customer-service.component'
    ),
  componentName: 'CustomerService',
})

export default function configRoutes(store) {
  // Language change listener
  observeStore(
    store,
    state => _.get(state, 'session.currentUser.meta.language', undefined),
    storeNewLanguageAndReload
  )

  const _checkAuthenticated = (nextState, replace, callback) => {
    const { dispatch } = store
    const { session } = store.getState()
    const { currentUser } = session

    if (api.loggedIn()) {
      if (!currentUser) {
        return dispatch(Actions.currentUser()).then(callback)
      }
      switch (nextState.location.pathname) {
        case '/login':
          replace('/')
          break
        case '/logout':
          dispatch(Actions.logout(currentUser.id))
          break
        default:
      }
    } else {
      // eslint-disable-next-line no-unused-expressions
      featureFlagService?.setIdentifier()
      if (nextState.location.pathname !== '/login') {
        replace('/login')
      }
    }
    callback()
  }

  const _checkParams = (nextState, replace) => {
    const { dispatch } = store
    const query = nextState.location.query

    switch (query.type) {
      case Constants.QUERY_TYPE_ACTIVATE:
        if (query.email && query.token) {
          return dispatch(
            push({
              pathname: '/set-password',
              query: {
                uniqueIdentifier: query.email,
                token: query.token,
                type: query.type,
              },
            })
          )
        }
        break

      case Constants.QUERY_TYPE_RESET_PASSWORD:
        if (query.email && query.token) {
          return dispatch(
            validResetPasswordToken(query.token, query.email, query.type)
          )
        }
        break

      default:
    }

    return replace('/errors/auth')
  }

  const _checkIfLoggedInAndNeedToRedirectToHome = useCallback(
    async (nextState, replace, callback) => {
      if (api.loggedIn()) replace('/')
      else {
        featureFlagService.setIdentifier()
        await Promise.all([
          featureFlagService.triggerFeatureFlag('self_registration'),
          featureFlagService.triggerFeatureFlag('login_mobile_view'),
          featureFlagService.triggerFeatureFlag(
            'disable_new_users_visiting_inner_pages'
          ),
          featureFlagService.triggerFeatureFlag('new_login_ui'),
          featureFlagService.triggerFeatureFlag('new_login_ui_ab_testing'),
        ])
      }

      callback()
    },
    []
  )

  const _checkIfNeedToDoLogout = useCallback(
    async (nextState, replace, callback) => {
      if (api.loggedIn()) {
        await store.dispatch(Actions.logout(getUserId()))
      } else {
        featureFlagService.setIdentifier()
      }

      replace('/login')
      callback()
    },
    [store]
  )

  return (
    <Route component={MainLayout} onEnter={handleLanguageParameter}>
      <Route
        path="/login"
        component={LoadableLogin}
        onEnter={_checkIfLoggedInAndNeedToRedirectToHome}
      />
      <Route path="/logout" onEnter={_checkIfNeedToDoLogout} />
      <Route path="/redirect" onEnter={_checkParams} />
      <Route
        path="/recovery"
        component={LoadableForgotPassword}
        onEnter={() => featureFlagService?.setIdentifier()}
      />
      <Route
        path="/customer-service"
        component={LoadableCustomerService}
        onEnter={() => featureFlagService?.setIdentifier()}
      />

      {SelfRegistrationRoutes(store)}
      {PrintLabelsRoute()}
      {PaidSubscriptionPlanRoutes(store)}

      <Route
        path="/"
        component={AuthenticatedContainer}
        onEnter={_checkAuthenticated}
      >
        <IndexRedirect to="home" />
        <Route path="errors">
          <Route path="auth" component={AuthErrorView} />
        </Route>
        {/**
         * App routes defined under /src/app.routes.js
         * The routes defined here should be moved to the above file as they are worked on
         */
        AppRoutes(store)}
      </Route>
      <Route
        path="*"
        component={PageNotFoundView}
        onEnter={() => featureFlagService?.setIdentifier()}
      />
    </Route>
  )
}
