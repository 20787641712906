import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getCountry } from 'ggx-service/locale/country.service'
import {
  SmallText,
  SmallTextBold,
} from 'ggx-componentlibrary/design/typography/typography.styles'
import { formatCurrency } from 'ggx-service/i18n/format-currency.service'

import {
  NavigationContainer,
  NavigationInnerContainer,
  CollapseButtonContainer,
  CollapseButton,
  LogoContainer,
  GogobusinessLogo,
  Row,
  RowSection,
  RowSectionSeparatorWrapper,
  RowSectionSeparator,
  Label,
  TopupButtonAmount,
  CollapseIcon,
  ExpandIcon,
  TopupIcon,
  HomeIcon,
  HomeActiveIcon,
  PlaceOrderIcon,
  PlaceOrderActiveIcon,
  OrderManagementIcon,
  OrderManagementActiveIcon,
  FavoriteDriverIcon,
  FavoriteDriverActiveIcon,
  BulkOrderIcon,
  BulkOrderActiveIcon,
  PaymentMethodIcon,
  PaymentMethodActiveIcon,
  TransactionHistoryIcon,
  TransactionHistoryActiveIcon,
  EcommerceIntegrationIcon,
  EcommerceIntegrationActiveIcon,
  CouponIcon,
  CouponActiveIcon,
  CollapseButtonHint,
  AdaptiveMenuBlock,
  UserInfoContainer,
  UserInfo,
  BalanceInfo,
  CollapseUserInfoContainer,
  ProfilePhoto,
  CouponBadge,
} from './navigation.component.styles'
import { ConnectedTopupModal } from '../../billing/topup-modal/topup-modal.component'
import { payByCreditCard } from '../../van-booking/duck/selectors'
import { getTotalCredit } from '../../billing/duck/selectors'
import { getFeatureFlagGogodelivery } from '../../delivery/duck/selectors'
import { featureConfig } from '../../legacy/utils/config'
import { isWalletUser } from '../../legacy/views/helpers/auth/auth'
import { FooterMenu } from './footer-menu/footer-menu.component'
import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'
import { Badge } from 'ggx-componentlibrary/components/badge/badge.component'
import { brazeClient, BRAZE_EVENT } from 'ggx-service/braze'
import { getActiveCouponNum } from 'ggx-global/duck/selectors'

import defaultProfilePhotoSrc from './assets/business.file.svg'
import {
  getAccountName,
  getCurrentUserLevel,
} from 'ggx-src/users-and-branches/duck/selectors'
import { ChevronIconNoMargin } from './footer-menu/footer-menu.component.styles'
import {
  getOrganizationName,
  getCurrentUserProfilePhotoList,
  getProfilePhotoDataUrl,
} from 'ggx-src/profile/duck/selectors'
import { Tooltip } from 'ggx-componentlibrary/components/tooltip/tooltip.component'

const Navigation = ({
  currentUser,
  isNavigationCollapsed,
  isNavigationHovered,
  setIsNavigationCollapsed,
  setIsNavigationHovered,
  showHomepage,
  showTransportBulkImport,
  showFavoriteDrivers,
  showBillingNavigation,
  showDeliveryBulkImport,
  activePath,
}) => {
  const [t] = useTranslation()

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)
  const [isOrganizationSwitchOpen, setIsOrganizationSwitchOpen] = useState(
    false
  )
  const [showTopupModal, setShowTopupModal] = useState(false)

  const isCreditCardUser = useSelector(state => payByCreditCard(state))
  const totalCredit = useSelector(state => getTotalCredit(state))

  const featureFlagGogodelivery = useSelector(state =>
    getFeatureFlagGogodelivery(state)
  )
  const featureFlagDeliveryIntegration = useSelector(state =>
    getFeatureFlag({ state, featureFlag: 'delivery_integration' })
  )
  const featureFlagNextDayDelivery = useSelector(state =>
    getFeatureFlag({ state, featureFlag: 'next_day_delivery' })
  )
  const featureFlagShopifyIntegration = useSelector(state =>
    getFeatureFlag({ state, featureFlag: 'shopify_integration' })
  )
  const featureFlagEnableCouponFeature = useSelector(state =>
    getFeatureFlag({ state, featureFlag: 'enable_coupon_feature' })
  )
  const couponNum = useSelector(state => getActiveCouponNum(state))

  const creditCardUserWithPrepaid = isCreditCardUser || featureFlagGogodelivery
  const creditCardUserWithNDD = isCreditCardUser && featureFlagNextDayDelivery

  const handleClick = () => {
    brazeClient.setCustomEvent(BRAZE_EVENT.TRANSPORT.NEW_ORDER)
  }

  const handleDeliveryClick = () => {
    brazeClient.setCustomEvent(BRAZE_EVENT.DELIVERY.NEW_ORDER)
  }

  const collapseButtonClickHandler = () => {
    setIsNavigationCollapsed()
    setIsNavigationHovered(false)
  }

  const homepageNavigation = (
    <Row to="/home" $isActive={activePath === '/home'}>
      <HomeIcon $isActive={activePath === '/home'} />
      <HomeActiveIcon $isActive={activePath === '/home'} />
      <Label>{t('common__home')}</Label>
    </Row>
  )

  const country = getCountry()
  const vanBookingOrderPath = '/van/order'
  const vanBookingNavigation = (
    <>
      <RowSection
        $isNavigationCollapsed={isNavigationCollapsed}
        $isNavigationHovered={isNavigationHovered}
      >
        <SmallText>
          {country === 'SG'
            ? t('text__delivery_and_transport_service')
            : t('common__van_truck_service')}
        </SmallText>
      </RowSection>
      <RowSectionSeparatorWrapper>
        <RowSectionSeparator
          $isNavigationCollapsed={isNavigationCollapsed}
          $isNavigationHovered={isNavigationHovered}
        />
      </RowSectionSeparatorWrapper>

      <Row
        to={vanBookingOrderPath}
        $isActive={activePath === '/van/order'}
        onClick={handleClick}
      >
        <PlaceOrderIcon $isActive={activePath === '/van/order'} />
        <PlaceOrderActiveIcon $isActive={activePath === '/van/order'} />
        <Label>{t('common__place_order_title')}</Label>
      </Row>
      {showTransportBulkImport && (
        <Row
          to="/van/bulk-import"
          $isActive={activePath === '/van/bulk-import'}
        >
          <BulkOrderIcon $isActive={activePath === '/van/bulk-import'} />
          <BulkOrderActiveIcon $isActive={activePath === '/van/bulk-import'} />
          <Label>{t('common__bulk_order')}</Label>
        </Row>
      )}
      <Row
        to="/orders"
        $isActive={activePath?.startsWith('/orders')}
        data-testid="navigation-bar-van-order-management"
      >
        <OrderManagementIcon $isActive={activePath?.startsWith('/orders')} />
        <OrderManagementActiveIcon
          $isActive={activePath?.startsWith('/orders')}
        />
        <Label>{t('common__order_management')}</Label>
      </Row>
      {showFavoriteDrivers && (
        <Row
          to="/van/favorite-drivers"
          $isActive={activePath === '/van/favorite-drivers'}
        >
          <FavoriteDriverIcon
            $isActive={activePath === '/van/favorite-drivers'}
          />
          <FavoriteDriverActiveIcon
            $isActive={activePath === '/van/favorite-drivers'}
          />
          <Label>{t('common__favourite_drivers')}</Label>
        </Row>
      )}
      {featureConfig.WALLET &&
        isWalletUser(currentUser) &&
        !showBillingNavigation && (
          <Row
            to="/wallet"
            $isActive={activePath === '/wallet'}
            data-testid="navigation-bar-billing-and-balance"
          >
            <TransactionHistoryIcon $isActive={activePath === '/wallet'} />
            <TransactionHistoryActiveIcon
              $isActive={activePath === '/wallet'}
            />
            <Label>{t('sidemenu.van.item.billing')}</Label>
          </Row>
        )}
    </>
  )

  const deliveryNavigation = (
    <>
      <RowSection
        $isNavigationCollapsed={isNavigationCollapsed}
        $isNavigationHovered={isNavigationHovered}
      >
        <SmallText>
          {creditCardUserWithNDD
            ? t('common__same_day_delivery')
            : t('common__courier_delivery')}
        </SmallText>
      </RowSection>
      <RowSectionSeparatorWrapper>
        <RowSectionSeparator
          $isNavigationCollapsed={isNavigationCollapsed}
          $isNavigationHovered={isNavigationHovered}
        />
      </RowSectionSeparatorWrapper>

      <Row
        to="/delivery/order"
        $isActive={activePath === '/delivery/order'}
        onClick={handleDeliveryClick}
      >
        <PlaceOrderIcon $isActive={activePath === '/delivery/order'} />
        <PlaceOrderActiveIcon $isActive={activePath === '/delivery/order'} />
        <Label>{t('common__place_order_title')}</Label>
      </Row>
      {showDeliveryBulkImport && (
        <Row
          to="/delivery/bulk-import"
          $isActive={activePath === '/delivery/bulk-import'}
        >
          <BulkOrderIcon $isActive={activePath === '/delivery/bulk-import'} />
          <BulkOrderActiveIcon
            $isActive={activePath === '/delivery/bulk-import'}
          />
          <Label>{t('common__bulk_order')}</Label>
        </Row>
      )}
      <Row
        to="/delivery/order-management"
        $isActive={activePath === '/delivery/order-management'}
        data-testid="navigation-bar-delivery-order-management"
      >
        <OrderManagementIcon
          $isActive={activePath === '/delivery/order-management'}
        />
        <OrderManagementActiveIcon
          $isActive={activePath === '/delivery/order-management'}
        />
        <Label>{t('common__order_management')}</Label>
      </Row>
      {featureFlagDeliveryIntegration && (
        <Row
          to="/delivery/integration"
          $isActive={activePath === '/delivery/integration'}
        >
          <EcommerceIntegrationIcon
            $isActive={activePath === '/delivery/integration'}
          />
          <EcommerceIntegrationActiveIcon
            $isActive={activePath === '/delivery/integration'}
          />
          <Label>{t('sideMenu__ecommerce_integration')}</Label>
          <Badge>{t('badges.new')}</Badge>
        </Row>
      )}
      {featureFlagShopifyIntegration && (
        <Row
          to="/delivery/shopify-integration"
          $isActive={activePath === '/delivery/shopify-integration'}
        >
          <EcommerceIntegrationIcon
            $isActive={activePath === '/delivery/shopify-integration'}
          />
          <EcommerceIntegrationActiveIcon
            $isActive={activePath === '/delivery/shopify-integration'}
          />
          <Label>{t('sideMenu__shopify_integration')}</Label>
        </Row>
      )}
    </>
  )

  const nextDayDeliveryNavigation = (
    <>
      <RowSection
        $isNavigationCollapsed={isNavigationCollapsed}
        $isNavigationHovered={isNavigationHovered}
      >
        <SmallText>{t('common__next_day_delivery')}</SmallText>
      </RowSection>
      <RowSectionSeparatorWrapper>
        <RowSectionSeparator
          $isNavigationCollapsed={isNavigationCollapsed}
          $isNavigationHovered={isNavigationHovered}
        />
      </RowSectionSeparatorWrapper>

      <Row
        to="/next-day-delivery/place-order"
        $isActive={activePath === '/next-day-delivery/place-order'}
      >
        <PlaceOrderIcon
          $isActive={activePath === '/next-day-delivery/place-order'}
        />
        <PlaceOrderActiveIcon
          $isActive={activePath === '/next-day-delivery/place-order'}
        />
        <Label>{t('common__place_order_title')}</Label>
      </Row>

      <Row
        to="/next-day-delivery/order-management"
        $isActive={activePath?.startsWith(
          '/next-day-delivery/order-management'
        )}
      >
        <OrderManagementIcon
          $isActive={activePath?.startsWith(
            '/next-day-delivery/order-management'
          )}
        />
        <OrderManagementActiveIcon
          $isActive={activePath?.startsWith(
            '/next-day-delivery/order-management'
          )}
        />
        <Label>{t('common__order_management')}</Label>
      </Row>
    </>
  )

  const billingNavigation = (
    <>
      <RowSection
        $isNavigationCollapsed={isNavigationCollapsed}
        $isNavigationHovered={isNavigationHovered}
      >
        <SmallText>{t('sidemenu.billing.title')}</SmallText>
      </RowSection>
      <RowSectionSeparatorWrapper>
        <RowSectionSeparator
          $isNavigationCollapsed={isNavigationCollapsed}
          $isNavigationHovered={isNavigationHovered}
        />
      </RowSectionSeparatorWrapper>

      <Row to="/payment-method" $isActive={activePath === '/payment-method'}>
        <PaymentMethodIcon $isActive={activePath === '/payment-method'} />
        <PaymentMethodActiveIcon $isActive={activePath === '/payment-method'} />
        <Label>{t('common__payment_method')}</Label>
      </Row>
      {featureFlagEnableCouponFeature && (
        <Row to="/voucher" $isActive={activePath === '/voucher'}>
          <CouponIcon $isActive={activePath === '/voucher'} />
          <CouponActiveIcon $isActive={activePath === '/voucher'} />
          <Label>{t('common__coupons')}</Label>
          <CouponBadge>
            <span>{couponNum}</span>
          </CouponBadge>
        </Row>
      )}
      <Row
        to="/wallet"
        $isActive={activePath === '/billing-history'}
        data-testid="navigation-bar-transaction-history"
      >
        <TransactionHistoryIcon $isActive={activePath === '/billing-history'} />
        <TransactionHistoryActiveIcon
          $isActive={activePath === '/billing-history'}
        />
        <Label>{t('common__transaction_history')}</Label>
      </Row>
    </>
  )

  const couponNavigation = (
    <>
      <RowSection
        $isNavigationCollapsed={isNavigationCollapsed}
        $isNavigationHovered={isNavigationHovered}
      >
        <SmallText>{t('sidemenu.billing.title')}</SmallText>
      </RowSection>
      <RowSectionSeparatorWrapper>
        <RowSectionSeparator
          $isNavigationCollapsed={isNavigationCollapsed}
          $isNavigationHovered={isNavigationHovered}
        />
      </RowSectionSeparatorWrapper>
      {featureFlagEnableCouponFeature && (
        <Row to="/voucher" $isActive={activePath === '/voucher'}>
          <CouponIcon $isActive={activePath === '/voucher'} />
          <CouponActiveIcon $isActive={activePath === '/voucher'} />
          <Label>{t('common__coupons')}</Label>
          <CouponBadge>
            <span>{couponNum}</span>
          </CouponBadge>
        </Row>
      )}
    </>
  )

  const handleMouseEnterOrOver = () => {
    if (isSubMenuOpen) return
    setIsNavigationHovered(true)
  }

  const handleMouseLeave = () => {
    if (isSubMenuOpen) return
    if (isOrganizationSwitchOpen) return

    setIsNavigationHovered(false)
  }

  const stopPropagateMouseOverToNavgationContainer = event => {
    // This event listener is used to avoid expanding navigation when hovering logo area
    event.stopPropagation()
  }
  const navigationInnerContainerRef = useRef()
  const [userMenuOffset, setUserMenuOffset] = useState()
  const handleToggleSubMenuClick = () => {
    setIsSubMenuOpen(prev => !prev)
    setUserMenuOffset(navigationInnerContainerRef.current?.scrollTop ?? 0)
  }
  const profilePhotoDataUrl = useSelector(state =>
    getProfilePhotoDataUrl(state)
  )
  const photosObject = useSelector(state =>
    getCurrentUserProfilePhotoList(state)
  )
  const profilePhotoSrc =
    profilePhotoDataUrl || photosObject?.small || defaultProfilePhotoSrc

  const userName = useSelector(state => getAccountName(state))
  const userLevel = useSelector(state => getCurrentUserLevel(state))
  const organizationName = useSelector(state => getOrganizationName(state))

  return (
    <NavigationContainer
      $isNavigationCollapsed={isNavigationCollapsed}
      $isNavigationHovered={isNavigationHovered}
      onMouseLeave={handleMouseLeave}
      data-testid="navigation-menu"
    >
      <CollapseButtonContainer onClick={() => collapseButtonClickHandler()}>
        <CollapseButton>
          {isNavigationCollapsed ? (
            <>
              <ExpandIcon />
              <CollapseButtonHint>
                {t('text__expand_sidebar')}
              </CollapseButtonHint>
            </>
          ) : (
            <>
              <CollapseIcon />
              <CollapseButtonHint>
                {t('text__collapse_sidebar')}
              </CollapseButtonHint>
            </>
          )}
        </CollapseButton>
      </CollapseButtonContainer>

      <NavigationInnerContainer
        ref={navigationInnerContainerRef}
        onMouseOver={handleMouseEnterOrOver}
        onScroll={() => setIsSubMenuOpen(false)}
      >
        <div onMouseOver={stopPropagateMouseOverToNavgationContainer}>
          <LogoContainer to="/home">
            <GogobusinessLogo
              $isNavigationCollapsed={isNavigationCollapsed}
              $isNavigationHovered={isNavigationHovered}
            />
          </LogoContainer>
        </div>

        <AdaptiveMenuBlock
          $isNavigationCollapsed={isNavigationCollapsed}
          $isNavigationHovered={isNavigationHovered}
        >
          <UserInfoContainer data-testid="navigation-bar-footer-menu-user">
            <UserInfo onClick={handleToggleSubMenuClick}>
              <div>
                <SmallTextBold noMargin title={userName}>
                  {userName}
                </SmallTextBold>
                <SmallText noMargins title={organizationName}>
                  {organizationName}
                </SmallText>
                <SmallText noMargin>
                  {userLevel === 'b2b_master_user' && t('common__master_user')}
                  {userLevel === 'b2b_branch_user' && t('common__branch')}
                  {userLevel === 'b2b_sub_user' && t('common__user')}
                </SmallText>
              </div>
              <ChevronIconNoMargin />
            </UserInfo>
            {creditCardUserWithPrepaid && (
              <Tooltip
                noMargin
                heading={
                  <BalanceInfo onClick={() => setShowTopupModal(true)}>
                    <TopupButtonAmount data-cy="topup-amount">
                      {formatCurrency({ amount: totalCredit })}
                    </TopupButtonAmount>
                    <TopupIcon />
                  </BalanceInfo>
                }
                content={t('common__top-up')}
                showTooltipIcon={false}
                isHeadingHoverable={true}
              />
            )}
          </UserInfoContainer>
          <CollapseUserInfoContainer>
            <ProfilePhoto $profilePhotoSrc={profilePhotoSrc} noMargin />
            {creditCardUserWithPrepaid && <TopupIcon />}
          </CollapseUserInfoContainer>
        </AdaptiveMenuBlock>

        {showHomepage && homepageNavigation}
        {vanBookingNavigation}
        {featureFlagGogodelivery && deliveryNavigation}
        {creditCardUserWithNDD && nextDayDeliveryNavigation}
        {showBillingNavigation && billingNavigation}
        {!showBillingNavigation && couponNavigation}
      </NavigationInnerContainer>
      <FooterMenu
        onMouseEnter={handleMouseEnterOrOver}
        onOutsideClick={() => setIsNavigationHovered(false)}
        isSubMenuOpen={isSubMenuOpen}
        subMenuOffset={userMenuOffset}
        setIsSubMenuOpen={setIsSubMenuOpen}
        isOrganizationSwitchOpen={isOrganizationSwitchOpen}
        setIsOrganizationSwitchOpen={setIsOrganizationSwitchOpen}
      />
      {showTopupModal && (
        <ConnectedTopupModal
          handleModalClose={() => setShowTopupModal(false)}
        />
      )}
    </NavigationContainer>
  )
}

Navigation.propTypes = {
  currentUser: PropTypes.object.isRequired,
  isNavigationCollapsed: PropTypes.bool,
  isNavigationHovered: PropTypes.bool,
  setIsNavigationCollapsed: PropTypes.func,
  setIsNavigationHovered: PropTypes.func,
  showHomepage: PropTypes.bool,
  showTransportBulkImport: PropTypes.bool,
  showFavoriteDrivers: PropTypes.bool,
  showBillingNavigation: PropTypes.bool,
  showDeliveryBulkImport: PropTypes.bool,
  activePath: PropTypes.string,
}

export { Navigation }
