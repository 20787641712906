import { SplitFactory } from '@splitsoftware/splitio'
import { appStore } from '../../store'

class FeatureFlagService {
  constructor() {
    this.resetService()
  }

  /**
   * When called without `organization`, we will login to Split with the "anonymous" as key.
   *
   * @param {object} organization An object containing the organization id, name and industry
   *
   * @returns void
   */
  setIdentifier(user = {}) {
    const { email, organization } = user
    const key = this._createUniqueIdentifier(organization)
    const attributes = this._createAttributes(email)

    // we make sure to not re-set the factory if the user is the same
    if (key === this._key) return
    if (this._factory) {
      this.resetService()
    }
    this._key = key

    const config = {
      startup: {
        requestTimeoutBeforeReady: 10,
        readyTimeout: 10,
      },
    }

    const configForCypress = {
      features: {
        favorite_drivers: 'on',
        gogodelivery_ggb: 'on',
        homepage_access: 'on',
        next_day_delivery: 'on',
        self_registration: 'on',
        shopify_integration: 'on',
        hourly_rental: 'on',
        ggb_web_transport_nid: 'on',
      },
      scheduler: {
        offlineRefreshRate: 15,
      },
    }

    this._factory = SplitFactory({
      core: {
        authorizationKey: window.Cypress ? 'localhost' : process.env.SPLIT_KEY,
        key,
      },
      ...(window.Cypress ? configForCypress : config),
    })

    this._factory.client().on(this._factory.client().Event.SDK_READY, () => {
      this._featureFlags = this._getAllFeatures(attributes)
      this._splitSDKReadyResolve()
    })

    this._factory
      .client()
      .on(this._factory.client().Event.SDK_READY_TIMED_OUT, () => {
        this._splitSDKReadyReject(
          new Error(
            'Feature flag : Split SDK has not fully loaded and timed out'
          )
        )
      })
  }

  /**
   * Dispatch an action composed of the FLAGNAME prefixed with FEATUREFLAG ie: FEATUREFLAG_FLAGNAME
   *
   * @param {string} flagName feature name from split.io
   * @param {string} defaultValue treatment from split.io (served when no response from split.io)
   *
   * @returns Promise
   */
  triggerFeatureFlag(flagName, defaultValue) {
    if (this._featureFlagsInUse[flagName]) {
      return this._splitSDKReady
    }

    this._featureFlagsInUse[flagName] = true
    return this._splitSDKReady
      .then(() => {
        if (!flagName) {
          console.warn(
            'Feature flag : You must specify a flagname as parameter when calling triggerFeatureFlag()'
          )
          return
        }

        if (typeof flagName !== 'string') {
          console.warn(
            'Feature flag : triggerFeatureFlag() takes a string as parameter'
          )
          return
        }

        appStore.getStore().dispatch(this._createAction(flagName))
      })
      .catch(() => {
        if (typeof defaultValue !== 'string') {
          console.warn('Feature flag : defaultvalue should be a string')
          return
        }

        appStore.getStore().dispatch(this._createAction(flagName, defaultValue))
      })
  }

  /**
   * Reset all private property of FeatureFlagService instance
   *
   * @returns void
   */
  resetService() {
    if (this._factory) {
      this._factory.client().destroy()
    }
    this._factory = null
    this._key = null
    this._featureFlags = {}
    this._featureFlagsInUse = {}
    this._splitSDKReady = new Promise((resolve, reject) => {
      this._splitSDKReadyResolve = resolve
      this._splitSDKReadyReject = reject
    })
  }

  _createUniqueIdentifier(organization) {
    const key = organization ? organization.id : 'anonymous'
    return `${key}_${localStorage.getItem('country')}`
  }

  _getAllFeatures(attributes = {}) {
    const splitNames = this._factory.manager().names()
    return this._factory.client().getTreatments(splitNames, attributes)
  }

  getFeatureWithConfig(splitName) {
    try {
      const attributes = this._createAttributes()
      const featureFlag = this._factory
        .client()
        .getTreatmentWithConfig(splitName, attributes)
      if (featureFlag) return JSON.parse(featureFlag.config)
    } catch (e) {
      console.warn(e)
    }

    return undefined
  }

  getFeatureFlagValue(splitName) {
    try {
      const attributes = this._createAttributes()
      return this._factory.client().getTreatment(splitName, attributes)
    } catch (e) {
      console.warn(e)
    }

    return undefined
  }

  _createAction(flagName, defaultValue) {
    const value = (defaultValue || this._featureFlags[flagName]) === 'on'
    return {
      type: `FEATUREFLAG_${flagName.toUpperCase()}`,
      value,
    }
  }

  _createAttributes(email) {
    return {
      country: localStorage.getItem('country'),
      product: 'GGB',
      email,
    }
  }
}

export const featureFlagService = new FeatureFlagService()
